import React from 'react';

const Regulations = () => {
    return (
        <div><p>תקנון האתר</p>

<p>א. כללי</p>

<p>אתר האינטרנט https://balaolam.co.il/ (להלן "האתר") הינו אתר בבעלות חברת "BaLaOlam" ומופעל על ידה (להלן: "החברה" ו/או "הנהלת האתר"). מטרת האתר הנה לספק לכם מענה מקיף ועדכני בכל ההיבטים החשובים הקשורים להבאת התינוק לעולם ולגידולו. באתר ניתן לרכוש תוכניות הכשרה הורית דיגיטליות וכן מוצרים רלבנטיים (להלן: "התוכניות" ו/או "שירותי האתר" ו/או "המוצרים"), אשר הגישה אליהם תינתן לרוכשים לאחר ביצוע התשלום (להלן: "המשתמש/ים" ו/או "הרוכש/ים" ו/או "הלקוח/ות"). </p>

<p>הוראות תקנון זה יחולו על כל גלישה באתר ו/או שימוש באתר מכל סוג שהוא ו/או רכישה של המוצרים/התוכניות המופיעים בו על ידי המשתמשים באתר (למען הסר ספק יובהר, כי "משתמש" לצורך תקנון זה הינו כל מי שמשתמש באתר, לכל מטרה שהיא, אף אם לא מתבצעת רכישה ו/או מתבצעת רכישה עבור צד שלישי כלשהו). ההוראות עשויות להשתנות מעת לעת, על פי שיקול דעתה הבלעדי של הנהלת האתר. אשר על כן, טרם ביצוע פעולה כלשהי באתר, מתבקש המשתמש לקרוא את תנאי השימוש ואת מדיניות הפרטיות.</p>

<p>הנהלת האתר שומרת לעצמה את הזכות הבלעדית להוסיף ו/או למחוק ו/או לשנות את תנאי שימוש אלו בכל עת ללא התחייבות למתן הודעה מוקדמת ו/או בדיעבד ללקוחות האתר. כל שינויים שתעשה באתר ובתנאיו יחייבו את המשתמש עם המשך שימושו בו. שימוש באתר ע"י המשתמש  לאחר ביצוע השינויים מעיד על הסכמת המשתמש לשינויים אלו. </p>

<p>הנהלת האתר שומרת לעצמה את הזכות להפסיק את פעילות האתר על מנת לבצע פעולות תחזוקה.</p>

<p>נוסח התקנון המחייב בכל זמן הינו נוסח התקנון המפורסם באתר בפועל.</p>

<p>הוראות תקנון זה יגברו על המצוי בתוכן האתר ויגברו עליו במקרה של סתירה ואי התאמה.</p>

<p>תנאי השימוש הינם מצטברים ולא חליפיים ויש לפרשם כמתקיימים זה לצד זה ולא כמצמצמים זה את תחולת זה.</p>

<p>שימוש באתר ו/או רכישת מוצרים על ידי המשתמש, מהווה אישור כי המשתמש קרא את הוראות תקנון זה וכן כי הוא מסכים להוראות תקנון זה, לתנאי מדיניות הפרטיות וכן מסכים לפעול לפיהן ולקבלן ללא כל הגבלה או הסתייגות. </p>

<p>על משתמש שאינו מסכים לתנאי השימוש, יש להימנע משימוש באתר.</p>

<p>האמור בלשון זכר משמעו גם בלשון נקבה ולהפך, האמור בלשון יחיד משמעו גם בלשון רבים ולהפך. </p>

<p>המשתמש רשאי לעשות באתר שימוש לצרכים אישיים בלבד.</p>

<p>הרישום לאתר והשימוש במידע אותו מסר המשתמש לחברה ו/או שהצטבר אצלה עקב שימוש המשתמש באתר, יתבצע בהתאם להוראות כל דין ובהתאם למדיניות הפרטיות של האתר, המהווה חלק בלתי נפרד מתנאי השימוש. </p>

<p>המשתמש מודע לכך ומסכים לכך שהשימוש באתר נעשה על אחריותו הבלעדית ובכלל זה המשתמש מודע ומסכים כי שימוש באתר, לרבות הסתמכות על כל תוכן, מידע, שירותים, דעות ועמדות המופיעים באתר ו/או הנשלחים אליו ו/או המצויים באתרים אחרים המקושרים לאתר, נעשה על פי שיקול דעת המשתמש ועל אחריותו הבלעדית וכי הנהלת האתר לא תהא אחראית לכל אי נוחות, עוגמת נפש, או לכל נזק, ישיר או עקיף, אשר ייגרם למשתמש עקב גישה או היעדר גישה לאתר.</p>

<p><br></br></p>

<p>ב. פעולות שאסור לבצע באתר</p>

<p>ללא קבלת אישור מראש ובכתב מידי הנהלת האתר, המשתמש איננו רשאי לבצע את הפעולות ו/או המעשים הבאים באתר ובתוכן האתר לרבות התוכניות:</p>

<p>לעשות שימוש מסחרי באתר ו/או בתוכן האתר ללא אישור מראש של הנהלת האתר.</p>

<p>להעתיק, לשחזר, לשנות, לעבד, לתרגם, לבצע הנדסה חוזרת, להפיץ, לשדר, להציג, לבצע,לשכפל, לפרסם ולאחסן את תוכן האתר, כולו או חלקו.</p>

<p>להפעיל או לאפשר הפעלת כל יישום מחשב או כל אמצעי אחר, לרבות תוכנות מסוג Crawlers, Robots וכדומה, לשם חיפוש, סריקה, העתקה או אחזור אוטומטי של תוכן האתר.</p>

<p>להציג תוכן מהאתר בתוך מסגרת (iframe) גלויה או סמויה או להציג את תוכן האתר בכל דרך שהיא, לרבות באמצעות כל תוכנה, מכשיר, אביזר או פרוטוקול תקשורת, המשנים את עיצובם המקורי באתר ו/או מחסירים דבר כלשהוא.</p>

<p>לשבש או להפר כל זכות של משתמש אחר באתר, לרבות הזכות לפרטיות ו/או לאסוף מידע אישי על המשתמשים באתר ללא הסכמתם המפורשת בכתב, לרבות באמצעים אוטומטיים.</p>

<p>לפגוע בכבודו או בפרטיותו של משתמש אחר ו/או להשתמש באתר ו/או בתוכן האתר כדי לפגוע בשמו הטוב של כל אדם ו/או לפרסם דברי הסתה, הונאה, תרמית, לשון הרע ו/או כל מידע אחר שהינו שקרי, בלתי אמין או שיש בו כדי לפגוע במזיד.</p>

<p>שימוש באתר או בתוכן האתר לשם יצירת מאגר מידע ו/או לקט.</p>

<p>מניפולציה של כתובת ה-URL של דפים פנימיים בכדי להגיע לדפים פנימיים אליהם אין לגולש גישה ישירה (URL Hacking.</p>

<p>יובהר, כי אי עמידה במגבלות אלו עלולה להוביל למניעת גישת המשתמש לאתר ואף לחשוף אותו לאחריות אזרחית ו/או פלילית, על-פי כל דין.</p>

<p>ג. רכישת תוכניות הכשרה ו/או מוצרים באתר</p>

<p>1. הנהלת האתר רשאית בכל עת ועל פי שיקול דעתה הבלעדי להוסיף ו/או לגרוע מוצרים ו/או תוכניות המוצעים למכירה באתר וכן לקבוע את אופן מכירתם ואת מחירם.</p>

<p>2. המחירים המתפרסמים באתר לצד התוכניות והמוצרים המוצעים למכירה, לרבות בדפי המוצר והתוכניות, הינם המחירים הרגילים של המוצרים בחברה ואינם מחירי שוק (להלן "המחיר הרגיל"). בהתאם לאמור, מחירי מבצע ו/או מחירים מיוחדים המוצגים ו/או שיוצגו באתר ביחס למוצרים אלו ואחרים הינם מחירים מוזלים ביחס למחיר הרגיל המתפרסם באתר של המוצרים האמורים.</p>

<p>5. לאחר בחירת המוצרים ו/או התוכניות המבוקשים ותשלום מלא ותקין בגינם (כאמור בסעיף ד' להלן), החברה תשלח לרוכשים חשבונית לכתובת המייל אשר הוזנה על ידי הרוכשים במעמד הרכישה.</p>

<p>ד. הזמנה באתר</p>

<p>1. לאחר בחירת התוכניות ו/או המוצרים המבוקשים על המשתמש לעבור ל"קופה"/דף הסליקה, שם יזין פרטי הזדהות, כתובת מייל למשלוח ופרטי כרטיס האשראי לחיוב. יש להקפיד על מסירת הפרטים הנכונים על מנת שהחברה תבטיח את ביצוע הקניה.</p>

<p>2.  ההזמנה תירשם במחשבי הנהלת האתר. כמו כן יישלח אישור בדואר אלקטרוני על ביצוע הפעולה תוך מספר שעות מסיום הליך ביצוע ההזמנה.</p>

<p>3. יובהר ויודגש, משלוח הדואר האלקטרוני אינו מהווה ראיה על ביצוע פעולה ואינו מחייב את הנהלת האתר. רק רישומי מחשב של הנהלת האתר, המנהלת רישום ממוחשב של כל הפעולות באתר כאמור, הם יהוו ראיה לכאורה לאמור בהם.</p>

<p>4. מובהר בזאת, כי הקלדת פרטים אישיים כוזבים אסורה לחלוטין ומהווה עבירה פלילית על פי הוראות חוק העונשין והעושה כן צפוי להליכים משפטיים, פליליים ואזרחיים, לרבות תביעות נזיקין בגין נזקים שיגרמו הנהלת האתר עקב שיבוש הליכי המכירה. </p>

<p>5. ביצוע פעולת תשלום באתר, יעשה ע"י בעל כרטיס האשראי. כל עסקה תדרוש אישור מקדים של חברת האשראי בהתאם לפרטים שנמסרו ע"י הלקוח לצורך ביצוע הרכישה.</p>

<p>6. באם העסקה לא תאושר על-ידי חברות האשראי יקבל הרוכש הודעה מתאימה. לצורך השלמת הרכישה יידרש הרוכש ליצור קשר עם הנהלת האתר תוך יום עסקים לצורך הסדרת אישור חברת האשראי לביצוע העסקה. יובהר ויודגש, פעולה תחשב כמושלמת רק לאחר אישור העסקה על-ידי חברת כרטיסי האשראי ובכפוף לעמידה בהוראות תקנון זה.</p>

<p>7. בקשה של רוכש לשינוי פרטי כרטיס אשראי או אופן החיוב, ככל שתאושר על ידי הנהלת האתר על פי שיקול דעתה הבלעדי, עשויה להיות מחויבת בתשלום עמלה על ידי חברת כרטיסי האשראי (ככל שתאושר על ידי חברת כרטיסי האשראי) והרוכש מתחייב לשאת בתשלום עמלה כאמור.</p>

<p>8. מחירי התוכניות והמוצרים המוצעים באתר והמבצעים שבו תקפים לרכישה באמצעות האתר בלבד ונתונים לשיקול דעתה הבלעדי של הנהלת האתר.</p>

<p>9. הנהלת האתר רשאית לסיים מבצעים ו/או לשנותם בכל עת. המחירים והתנאים הקובעים הינם אלה המופיעים בדף המוצר באתר בפועל.</p>

<p>ה. מי רשאי לבצע הזמנה באתר</p>

<p>1. כדי לבצע רכישה באתר על המשתמש לעמוד בתנאים המצטברים שלהלן:</p>

<p>בני 18 ומעלה בעלי תעודת זהות ישראלית תקפה או תאגיד המאוגד והרשום כדין בישראל. היה והרוכש קטין (מתחת לגיל 18) או אינו זכאי לבצע פעולות ללא אישור אפוטרופוס, יראה השימוש באתר כמאושר על ידי האפוטרופוס.</p>

<p>בעלי כרטיס אשראי ישראלי תקף, בעלי תא דואר אלקטרוני ובעלי אמצעי התקשרות בישראל (טלפון נייח ו/או טלפון נייד).</p>

<p>למען הסר ספק מובהר, כי הנהלת האתר נתונה הרשות בכל עת ועל פי שיקול דעתה הבלעדי למנוע ממשתמש את הגישה לרכישת מוצרים ו/או לבטל את חשבונו באתר החברה, היה והתנהגותו אינה הולמת ו/או היה והוא מבצע שימוש בלתי ראוי או בלתי סביר באתר, לרבות אי ציות לתנאי השימוש ו/או למדיניות הפרטיות ו/או אם כרטיס האשראי של המשתמש נחסם או הוגבל לשימוש. הנהלת האתר תהיה רשאית להתנות את הכניסה ו/או השימוש באתר, כולו או חלקו, ברישום ו/או בתשלום, ומבלי שתהיה למשתמש כל טענה, זכות ו/או תביעה בקשר לכך.</p>

<p>ו. לא ניתן לבטל עסקה ו/או לקבל החזר כספי בגין תוכניות ההכשרה</p>

<p>לפי חוק, ברכישת מוצרים דיגיטליים באתר, לא ניתן לבטל את העסקה ולא יינתן החזר כספי. </p>

<p>רכישת גישה לתוכניות הכשרה ו/או רכישת קובץ להורדה מאפשרת צריכת תכנים&nbsp;מיידית.&nbsp;ועל מנת למנוע מצב בו לקוח ירכוש גישה לתוכניות, יצפה בתכנים ולאחר מכן יבקש החזר כספי בדיעבד- ננקטת באתר זה מדיניות&nbsp;איסור ביטול עסקאות מוחלט (בהתאם להוראת סעיף 6(א)(8) לתקנות הגנת הצרכן (ביטול עסקה), תשע"א – 2010).</p>

<p>ז. שימוש בתוכניות הדיגיטליות הנמכרות דרך האתר</p>

<p>התוכניות המוצעות לרכישה באתר הינן אישיות ולא ניתנות להעברה לאדם או ישות אחרת מלבד הרוכש המקורי שלהם.</p>

<p>אין לבצע שום שימוש מלבד לימוד אישי בתוכניות או בתכנים המוצעים. אין לבצע שכפול, שעתוק, העתקה ושכתוב, הדרכה פרונטאלית, העתקת תוכן הדרכה, לא אישור מהנהלת האתר מראש ובכתב, הכל עפ"י דין חוק זכויות יוצרים.</p>

<p>התוכניות הן בפורמט אינטרנטי ומוקלטות מראש, הכניסה מתבצעת באמצעות שם משתמש וסיסמה.</p>

<p>התשלום עבור התוכניות כולל גישה לתכנים המוקלטים בלבד ואינו כולל תמיכה או מענה לשאלות פרטניות.</p>

<p>חל אסור לצלם, להעביר ו/או להפיץ את תוכן התוכניות.</p>

<p>הנהלת האתר תהיה רשאית לנקוט באמצעים שונים למניעת גישה לא מורשית  לתכנים לרבות באמצעות זיהוי פרטי משתמש לצורך קבלת גישה לתוכניות, הגבלת מספר המכשירים הרשאים לצפות בתכנים בו זמנית, הכל על פי שיקול דעתה הבלעדי של הנהלת האתר. </p>

<p>ז. ביטול עסקה על ידי הנהלת האתר</p>

<p>    מבלי לגרוע מהוראות תקנון זה, בכל אחד מהמקרים שלהלן תהיה הנהלת האתר רשאית לבטל</p>

<p>    את ההזמנה ו/או ההתקשרות עם המשתמש, גם במקרה בו נשלחה הודעה על ההזמנה</p>

<p>    למשתמש. התקשרות שבוטלה לא תזכה את המשתמש בכל סעד שהוא ולא יהיו למשתמש כל</p>

<p>    טענות כנגד החברה:</p>

<p>במקרה של טעות בתום לב באחד מן הנתונים המפורטים באתר לגבי התוכניות ו/או המוצרים, לרבות מחירם או תיאורם על בסיסם נערכה ההזמנה.</p>

<p>במקרה של השבתה או תקלה בפעילותו התקינה של האתר עקב "כוח עליון", ו/או עקב מקרה של תקלה מהותית במערכת המחשב, במערכת הטלפונים או במערכות תקשורת אחרות המשרתות את הנהלת האתר, ו/או בכל מקרה אחר בו יעכבו, ימנעו או ישבשו גורמים ו/או אירועים שאינם בשליטת הנהלת האתר את פעילותו התקינה של האתר או את אספקת המוצרים.</p>

<p>במקרה בו לא עמד המשתמש בהתחייבות מהתחייבויותיו לרבות, ההתחייבות לתשלום התמורה בגין התוכניות ו/או המוצרים שהזמין ו/או בכל מקרה בו מסר המשתמש פרטים לא נכונים ו/או לא מדויקים ו/או לא מושלמים, לרבות במקרה של אי אישור העסקה על ידי חברת כרטיסי האשראי.</p>

<p>ח. בעלות וקניין רוחני</p>

<p>1. האתר והמידע המופיעים בו לרבות עיצוב האתר, לרבות כל מידע מכל מין וסוג שהוא, ובכלל זה כל תוכן מילולי, חזותי, קולי, אור-קולי (audio-visual), ולרבות טקסט, תמונה, קול, וידאו, תוכניות מצולמות, בכל אמצעי מדיה ובכל מכשיר קצה, הקיימים היום ו/או שיהיו קיימים בעתיד ו/או כל שילוב שלהם לרבות כתבות, מאמרים, ידיעות, סקירות, נתונים, קבצים, מפות, עצות, ניתוחים, המלצות, הנחיות, הערכות וכל מידע ו/או תוכן אחר בכל פורמט שהוא, וכן עיצובם, עיבודם, עריכתם, הפצתם ודרך הצגתם, לרבות (אך לא רק): כל תמונה, שרטוט, עיצוב, צילום, איור, הנפשה (animation), תרשים, דמות, הדמיה, דגימה (sample), סרטון, קובץ קולי וקובץ מוסיקלי; כל תוכנה, קובץ, קוד מחשב, יישום, תסדיר (format), פרוטוקול, מאגר נתונים וממשק וכל תו, סימן, סמל וצלמית (icon) (להלן "התוכן" או "התכנים"), מוגנים על-ידי דיני זכויות יוצרים של מדינת ישראל, אמנות בינ"ל וחוקי זכויות יוצרים של מדינות אחרות. נאסר על המשתמש להכניס שינויים, להעתיק, לפרסם, להפיץ, לשדר, להציג, לבצע, לשכפל, להנפיק רישיון, ליצור עבודות נגזרות או למכור כל חלק מן התוכן הכלול באתר ללא הסכמתה המוקדמת, הכתובה והמפורשת של הנהלת האתר.</p>

<p>2. כל זכויות הקניין הרוחני בקשר עם האתר מכל מין וסוג שהוא (וזאת בין אם המדובר בזכויות רשומות ובין אם המדובר בזכויות שטרם נרשמו), ובכלל זאת שם המתחם ("Domain"), הפטנטים, סימני המסחר, שמות המסחר, המדגמים, זכויות יוצרים, סודות מסחריים, המוניטין, 'Know How', שיטות, קוד המקור והקוד הבינארי, סמלים מסחריים, תוכנות מחשב, מושגים, מידע סודי, סימני שירות, מאגרי מידע, יצירות נגזרות, תגליות, נוסחאות, מוניטין, רעיונות, שיפורים, מדגמים, מידע, חידושים, המצאות, ידע, לוגו, נתוני שוק, שיטות, זכויות מוסריות, יצירות ספרותיות, קובץ גרפי, מידע טכני, אופן הצגתו ועיצובו של האתר וכן כל עניין או פרט הקשורים באתר, הם רכושה הבלעדי של הנהלת האתר או של צד שלישי, אשר הרשה לחברה להשתמש בהן, והשימוש בכל הזכויות האמורות מותר להנהלת האתר בלעדית (או לצדדים שלישיים נוספים, במידה והם בעלי רישיון לעשות כן). </p>

<p>אין להעתיק, להפיץ, לשכפל, למכור, לתרגם ו/או לבצע פעולה אחרת כלשהי, מסחרית ושאינה מסחרית, בכל סוגי הטקסטים ו/או קודים ו/או תמונות ו/או סימני מסחר ו/או תצלומים ו/או סרטונים ו/או כל תוכן אחר המופיע באתר, אלא אם התקבל אישור הנהלת האתר לכך מראש ובכתב.</p>

<p>3. מבלי לגרוע מהאמור, שם האתר וסימני המסחר שלו, לרבות אובייקטים הקשורים למוניטין האתר, כגון (אך לא רק): סימנים, לוגו, סמלילים, סימני שירות, או כל אובייקט אחר כפי שהם מופיעים באתר, הינם קניינה הבלעדי של הנהלת האתר ואין לעשות בהם כל שימוש.</p>

<p>4. הנהלת האתר שומרת לעצמה את הזכות להעביר או למכור או להמחות את הפעילות המסחרית ו/או האתר באופן מלא או חלקי לצד שלישי כלשהו. </p>

<p>ט. תוכן האתר ופרסומות</p>

<p>האתר בכללותו, כולל כל התכנים המופיעים בו מוצעים לציבור ולמשתמש כמות שהם ('As Is'). כוונת הנהלת האתר היא שהתוכן המופיע באתר יהיה נכון ומדויק ואולם, יתכן והתוכן אינו שלם ולחלופין יתכן ונפלו טעויות טכניות או אחרות בתוכן.</p>

<p>הנהלת האתר אינה אחראית לאי דיוקים, או לטעויות שנפלו ביחס לתוכן. הנהלת האתר מבהירה בזאת שהתוכן הכלול באתר הינו למטרות כלליות ואינפורמטיביות בלבד ואינו מהווה המלצה ו/או חוות דעת ביחס למוצרים המתפרסמים באתר.</p>

<p>יתרה מזאת – תכני התוכניות המוצעות, המידע והעצות הניתנים באתר ו/או בתוכניות ההכשרה הם בגדר מידע כללי ואין בהם  כדי להוות תחליף לבדיקה או לייעוץ אצל רופאים, ואינם בגדר "אבחנה רפואית", "חוות דעת רפואית", "המלצה לטיפול רפואי" או "תחליף לטיפול רפואי". ועל כן מודע המשתמש ומסכים לכך שכל הסתמכות על התכנים, ההצהרות, הבעות העמדה, העצות ו/או כל תוכן אחר המוצג בתוכניות ו/או באתר נעשה לפי שיקול דעתו של המשתמש ובאחריותו הבלעדית; על המשתמש לבצע בעצמו את כל הערכותיו ובדיקותיו לגבי המוצרים ותכניהם והוא מוותר ויהיה מנוע מלהעלות כל טענה לגבי הסתמכות על מי מאלה ופוטר את הנהלת האתר מכל אחריות בקשר לכך. בכל מקרה שבו קיימת בעיה רפואית או מתעורר חשד לקיומה, יש להזמין ביקור אצל רופא ולהיבדק.</p>

<p>בעצם השימוש באתר, בפורומים של האתר ובבלוגים של האתר, ובתוכניות המוצעות בו - המשתמש מוותר על כל תביעה, דרישה או טענה מכל סוג שהוא כלפי הנהלת האתר ו/או צוות הכותבים, העורכים, המומחים והיועצים של האתר.</p>

<p>הנהלת האתר לא תישא בכל אחריות לנזק ו/או אבדן ו/או הפסד בגין ו/או בקשר לאתר ו/או לתכניו ו/או לשימוש בו, לרבות ביחס לשימוש ו/או אי יכולת שימוש באתר, הסתמכות על תוכן המופיע באתר, אי זמינות האתר, אי גישה אל האתר, הפרעות באתר ו/או תקינותו.</p>

<p>המשתמש מצהיר כי הינו האחראי הבלעדי לכל שימוש שהוא עושה באתר ו/או בהסתמכו על איזה מתכני האתר. הנהלת האתר לא תישא באחריות כלשהי בקשר עם תוכן האתר ו/או בקשר להתאמת האתר לצרכי המשתמש.</p>

<p>יובהר כי תמונות המוצגות באתר הנן להמחשה בלבד ואינן מחייבות באופן כלשהו את הנהלת האתר.</p>

<p>תכונות, דגמים, עיצוב, צבעים או חזות של מוצרים המתוארים או מוצגים באתר, לרבות כל תמונה או הצגה של מוצר הכלולים באתר, הינם על מנת לדמות למשתמש את המוצר המבוקש בצורה הקרובה והטובה ביותר. ככל ומשתמש זיהה ו/או חושד כי ישנו פער בין נראות המוצר המבוקש בפועל לבין המוצר המופיע באתר, בצמוד לתיאור המוצר המבוקש, עליו לפנות הנהלת האתר אשר תפעל לתקנו בהקדם, בהתאם לצורך.</p>

<p>תנאיו ותוקפו של כל מבצע המפורסם באתר יהיו כפופים לפרק הזמן שהוקצב לאותו מבצע כמפורסם באתר.</p>

<p>באתר עשויים להיכלל קישורים לאתרי אינטרנט אחרים, שאינם מופעלים על-ידי הנהלת האתר. הקישורים נועדו אך ורק לנוחותם ולידיעתם של המשתמשים. קישורים אלו אינם בשליטתה של הנהלת האתר והיא אינה מפקחת על תוכן האתרים המקושרים. ככל שהאתר מכיל קישורים אלו, אין בכך כדי להעיד על הסכמה ו/או אחריות של הנהלת האתר לתכנים המופיעים באתרים המקושרים ו/או מהווה ערובה לאמינותם, עדכניותם, תקינותם או חוקיותם ו/או למדיניות הפרטיות ותנאי השימוש הננקטים על ידי בעליהם. הנהלת האתר אינה אחראית לכל תוצאה שתיגרם כתוצאה מהשימוש באתרים המקושרים ו/או מהסתמכות עליהם וממליצה לקרוא בעיון את תנאי השימוש ומדיניות הפרטיות של אתרים אלו, ככל שקיימים, ו/או לפנות לבעלי האתרים המקושרים בכל מקרה של טענה או בקשה בעניין. הנהלת האתר רשאית לפי שיקול דעתה הבלעדי להסיר כל קישור מהאתר ו/או להוסיף קישורים נוספים. הנהלת האתר אינה מתחייבת כי הקישורים לאתרי האינטרנט האמורים יהיו פעילים ויובילו לאתר אינטרנט פעיל.</p>

<p>כל שימוש בתוכן באתר, לרבות בתוכן המסופק על ידי הנהלת האתר (לרבות מדריכים, מאמרים, מפרטים, הוראות שימוש ו/או תמונות) ו/או בתוכן של צד שלישי, לרבות כל כניסה של המשתמש לאתרים של צדדים שלישיים על ידי הפניה באתר ו/או בפרסומת באתר, יתבצע על אחריותו הבלעדית של המשתמש. למשתמש לא תהיה כל טענה ו/או תביעה כנגד הנהלת האתר בקשר לתוכן באתר, לרבות לתוכן המסופק על ידי הנהלת האתר ו/או לתוכן של צד שלישי וכן, לרבות תוכן לגבי מוצרים ו/או מבצעים, בגין כל נזק ישיר או עקיף, הנובע משימוש ו/או כניסה כאמור ו/או בשל הסתמכות על תוכן של צד שלישי ו/או בשל פגיעה בפרטיות בעקבות שימוש כאמור.</p>

<p>הנהלת האתר משתדלת שהמידע המובא באתר יהיה מדויק ככל הניתן. עם זאת, ייתכנו באתר ו/או בתוכן ובמידע המובאים בו, לרבות לגבי כל מוצר ו/או שירות ו/או מידע על מבצעים, שיבושים, אי דיוקים וטעויות, בין היתר עקב מעשים ו/או מחדלים של הנהלת האתר ו/או מי מטעמה ו/או של צדדים שלישיים. המשתמש פוטר בזאת את הנהלת האתר מכל אחריות בשל המידע ו/או התוכן המובא על ידה באתר ו/או טעויות בו, לרבות בגין כל נזק, ישיר או עקיף, הנגרם למשתמש בשל כל תוכן ו/או המידע כאמור ו/או הסתמכות המשתמש עליו, לרבות בקשר למוצרים, תיאורים, מחירים וכדומה.</p>

<p>ידוע למשתמש, כי יתכן ולא כל תכונות האתר יפעלו כראוי במערכות הפעלה ישנות ולא עדכניות (כגון חלונות XP, דפדפן אקספלורר 9 ומטה).</p>

<p>מובהר למען הסר ספק, כי ייתכן שוני בשימוש באתר באמצעות אתר מותאם למכשירי טאבלט ו/או טלפון ניידים (mobile) לבין שימוש באתר במחשבים נייחים ו/או ניידים.</p>

<p>שליחת תגובות במייל ו/או פרסום תגובות באתר – הנך מסכים, כי עצם פרסום תגובה מהווה הצהרה מטעמך, כי אתה בעל מלוא זכויות הקנין הרוחני בתוכן וכי אתה מרשה בעצם מסירת התכנים, לעשות בהם כל שימוש לרבות פרסומם ו/או הסרתם מהאתר, ללא כל מגבלה או תשלום. מבלי לגרוע מכלליות האמור, חל איסור מוחלט לפרסם פרסומים פוגעניים מכל סוג שהוא, אשר הינם בניגוד לחוק ו/או פוגעים באחר ו/או מוציאים את דיבתו ו/או פוגעים בפרטיות ו/או פוגעים בזכויות יוצרים או קנין רוחני. כמו כן יובהר, כי כל תגובה תתפרסם בציון שמך המלא. הנך רשאי לפנות להנהלת האתר, בעל עת, בבקשה להסיר את שמך ו/או תגובתך מהאתר. </p>

<p>יא. הגבלת אחריות</p>

<p>1. הנהלת האתר עושה מאמצים רבים כדי לספק לך חווית שימוש איכותית ובטוחה באתר. עם זאת, האתר אינו חסין מתקלות ו/או בעיות ועשויה שלא להיות לך גישה לאתר מעת לעת.</p>

<p>2.  ידוע למשתמש, כי האתר, לרבות כל תוכן ו/או שירות בו, ניתנים על ידי הנהלת האתר במצבם כפי שהם ובכפוף לזמינותם ('As Is' ו- 'As Available'). הנהלת האתר לא תישא בכל אחריות שהיא, מפורשת או משתמעת בקשר עם האתר, לרבות כל תוכן או שירות בו, וכן בקשר עם התאמתם למטרה ספציפית ו/או לדרישות המשתמש. המשתמש מצהיר ומתחייב בזה, כי הוא אחראי באופן בלעדי ומלא לכל שימוש שהוא עושה באתר, לרבות בכל תוכן ו/או שירות בו, וכי ידוע לו שהנהלת האתר אינה אחראית, בין במישרין בין בעקיפין, לכל שימוש שהוא עושה באתר כאמור.</p>

<p>3. הנהלת האתר ו/או מי מטעמה, לא יישאו בכל אחריות שהיא, לנזק ו/או אובדן, ישיר או עקיף, לרבות נזק נלווה, תוצאתי, אקראי או פיצויים עונשיים (ובכלל זה, ומבלי לגרוע מכלליות האמור, פיצויים בגין הפסד עבודה ועסקים, הפסד רווחים, הפסקות והפרעות עבודה, הפסד ו/או איבוד מידע עסקי, פגיעה במוניטין, וכל הפסד ו/או נזק כספי אחר, צפוי ושאינו צפוי), הנובעים ו/או הקשורים, באתר, או בכל תוכן ו/או שירות בו, או בכל שימוש בהם, ולרבות אך מבלי לגרוע:</p>

<p>בשימוש ו/או אי יכולת שימוש באתר, לרבות כל תוכן ו/או שירות בו, מכל סיבה שהיא;</p>

<p>הודעות ו/או קבצים שנתקבלו אצל המשתמש במהלך ו/או עקב השימוש באתר, לרבות כל תוכן ו/או שירות בו; </p>

<p>שימוש או הסתמכות על מידע ותכנים המתפרסמים באתר, בין על ידי הנהלת האתר ובין על ידי צדדים שלישיים;</p>

<p>כל מעשה ו/או מחדל המתבצע בתוכן האתר או בקשר עמו לאחר מסירתו לצדדים שלישיים;</p>

<p>הפרעות, זמינות ותקינות של האתר, לרבות כל תוכן ו/או שירות בו, מכל סיבה שהיא, ולרבות, הנובעים משיבושים או מכשלים ברשת האינטרנט או ברשת הטלפוניה;</p>

<p>נזק או אובדן אשר נגרם כתוצאה משגגה, טעות, אי דיוק וכדומה, בתוכן באתר.</p>

<p>4. שיבוש בקליטת פרטי לקוח במחשבי הנהלת האתר מכל סיבה שהיא, לרבות תקלה הקשורה להנהלת האתר, לא יהווה עילה לכל טענה ו/או תביעה מצד המשתמשים. כך, גם לעניין פעולת רכישת מוצר באמצעות האתר אשר לא נקלטה במחשבי הנהלת האתר עקב שיבוש כאמור.</p>

<p>5. הנהלת האתר תישא באחריות מקסימלית עד לגובה עלות המוצר כפי שנרכש ע"י הלקוח.</p>

<p>6. אין בהגבלת האחריות בסעיף ‏זה, בכדי לגרוע מכל הגבלת אחריות אחרת המפורטת בתנאי השימוש, לרבות מדיניות הפרטיות.</p>

<p>יב. קיום הוראות התקנון ושיפוי</p>

<p>1. המשתמש מתחייב בזאת לציית לכל הוראות הדין, לרבות חוק ו/או תקנה, העשויים לחול על שימושו באתר ו/או בתוכנו, לרבות תנאי שימוש אלו.</p>

<p>2. כמו כן, המשתמש מתחייב לשפות את הנהלת האתר ו/או מי מטעמה בגין כל נזק, הפסד, אובדן רווח, תשלום או הוצאה שיגרמו להם, במישרין או בעקיפין, ובכלל זה פגיעה במוניטין, פגיעה כלכלית ו/או מסחרית, שכ"ט עו"ד והוצאות משפט בגין כל טענה, דרישה ו/או תביעה הנובעים, במישרין ו/או בעקיפין, מהפרת תנאי השימוש ו/או כל ממעשה ו/או מחדל של המשתמש ו/או מכל חיוב בו תחויב הנהלת האתר ו/או מי מטעמה אשר על פי תנאי השימוש להנהלת האתר אין אחריות לגביו; או שתועלה נגדם על ידי צד שלישי כלשהו כתוצאה משימוש פסול ו/או בלתי חוקי על ידי המשתמש באתר ו/או בתוכנו, לרבות הפרת תנאים אלו.</p>

<p>יג. הגבלת גישה לאתר ו/או ביצוע שינויים באתר</p>

<p>1. הנהלת האתר רשאית להתיר או לאסור את גישת המשתמש לאתר בכל זמן על פי שיקול דעתה הבלעדי ושומרת לעצמה את הזכות הבלעדית לשנות או להפסיק את הפעלת האתר, כולו או חלקו, בכל זמן וללא מתן הודעה.</p>

<p>2. מבלי לגרוע מהאמור, תהיה הנהלת האתר רשאית להפסיק את הפעילות המתבצעת באתר, כולה או חלקה, באופן זמני ו/או קבוע, בכל עת, ומבלי שתהיה למשתמש כל טענה, זכות ו/או תביעה בקשר לכך.</p>

<p>3. הנהלת האתר תהיה רשאית להתנות את הכניסה ו/או השימוש באתר, כולו או חלקו, ברישום ו/או בתשלום, ומבלי שתהיה למשתמש כל טענה, זכות ו/או תביעה בקשר לכך.</p>

<p>יד. התיישנות</p>

<p>מבלי לגרוע מהאמור בתקנון תנאי שימוש זה, המשתמש מודע, מסכים ומאשר בזאת, כי תקופת ההתיישנות לגבי כל תביעה ו/או טענה, ו/או דרישה כנגד הנהלת האתר ו/או צדדים שלישיים הקשורים לשימוש באתר על ידי המשתמש ו/או למוצרים המופיעים באתר, תוגבל לתקופה של 6 חודשים וכלל הצדדים רואים בכך כהסכם לתקופת ההתיישנות כמשמעו בסעיף 19 בחוק ההתיישנות, תשי"ח-1958.</p>

<p>טו. סמכות שיפוט והדין החל</p>

<p>על כל שימוש באתר ו/או על כל תביעה, דרישה או בקשה הנובעים משימוש זה ו/או מכל דבר ועניין אחר הקשורים באופן ישיר או עקיף לאתר זה יחולו דיני מדינת ישראל בלבד. מקום השיפוט יהיה בבית המשפט לו הסמכות בעיר תל אביב-יפו, והדין המהותי והפרוצדוראלי שיחול יהיה הדין הישראלי, אשר יגבר על כל כללי ברירת דין המפנים לתחולת דין זר.</p>

<p>טז. שונות</p>

<p>1. תנאי שימוש המפורטים לעיל אינם יוצרים ולא יפורשו כיוצרים כל שותפות, מיזם משותף, יחסי עובד מעביד, סוכן או שלוח בין המשתמש לבין הנהלת האתר. </p>

<p>2. אם תנאי מתנאי השימוש ימצא בלתי חוקי, בטל או בלתי אכיף מכל סיבה, תנאי זה ימחק מתנאי השימוש ומחיקתו לא תשפיע על חוקיות ותקפות תנאי השימוש הנותרים. במקרה שכזה, יראו בקיומו של תנאי דומה אחר אכיף במקום התנאי שנמחק או הוסר.</p>

<p>3. תנאי שימוש אלו מהווים את ההסכם המלא בינך לבין הנהלת האתר בכל הנוגע לאופן השימוש באתר ומחליפים כל הבנה ו/או הסכמה אחרת, בעל-פה או בכתב, הנוגעת לשימוש באתר ובתוכנו.</p>

<p>4. מובהר בזאת, כי השימוש בלשון זכר בתנאי השימוש, נעשה מטעמי נוחות בלבד ואין בו כדי לפגוע ו/או ליצור אפליה כלשהי.</p>

<p>יז. צור קשר</p>

<p>בכל עניין, שאלה ו/או בקשה, אנא פנו הנהלת האתר באמצעות טופס יצירת קשר, בטלפון: 052-7247414  או במייל : <a href="mailto:Info@Balaolam.co.il">Info@Balaolam.co.il</a>.</p>

</div>
    );
};

export default Regulations;
