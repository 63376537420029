import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy" style={{ direction: 'rtl' }}>
            <h2>מדיניות פרטיות</h2>
            <p>
                א. כללי<br />
                1. בעלי אתר https://balaolam.co.il/ (להלן "הנהלת האתר") מכבדים את פרטיותם של המשתמשים הנכנסים לאתר ו/או המשתמשים בשירותים השונים המוצעים במסגרתו. מטרת התנאים המפורטים במדיניות פרטיות זו, המהווים חלק בלתי נפרד מתנאי השימוש, הנה לסקור, בין היתר, את האופן שבו משתמשת הנהלת האתר במידע שנמסר לה על ידי המשתמשים באתר ו/או נאסף על ידה בעת השימוש באתר וכן את האופן שבו מבצע המשתמש שימוש בתוכן המצוי באתר ו/או אליו נחשף המשתמש באמצעותו.<br /><br />
                2. תנאי מדיניות הפרטיות המפורטים להלן, מהווים חלק בלתי נפרד מתנאי השימוש. הנהלת האתר רשאית לשנות מעת לעת את הוראות מדיניות הפרטיות שלה, כדי שישקפו שינויים טכנולוגיים, עסקיים, משפטיים או רגולטורים. במקרה שכזה, השינוי יעודכן במסמך זה ויפורסם באתר. שימושך באתר לאחר ביצוע השינויים, יעיד על הסכמתך לשינויים אלו, ועל כן אנו ממליצים לעיין במדיניות זו מעת לעת.<br /><br />
                3. כל הגדרות והמונחים במדיניות הפרטיות יהיה כמשמעם בתנאי השימוש, אלא אם הקשרם של הדברים מחייב אחרת.<br /><br />

                ב. רישום באתר ומסירת מידע להנהלת האתר<br />
                1. חלק מהשירותים באתר טעונים מסירת מידע אישי. במסגרת רכישת מוצרים תידרש למסור מידע אישי, כדוגמת שמך, כתובתך, דרכי ההתקשרות עמך וכתובת הדואר האלקטרוני שלך. השדות אשר חובה למלאם, יסומנו במפורש. בלי למסור את הנתונים המתבקשים בשדות החובה לא תוכל לבצע הזמנת מוצרים באמצעות האתר (להלן "ההרשמה").<br /><br />
                2. להנהלת האתר מאגר מידע בו ישמרו פרטיך (שם, כתובת, טלפון ומייל), אשר בין מטרותיו דיוור ישיר וקשר עם הלקוח, מתן שירות ללקוחות, ניהול מועדון לקוחות, ניהול מכירות, שיווק וגיוס לקוחות.<br /><br />
                3. מובהר בזאת, כי לא חלה על המשתמש כל חובה שבדין למסור את המידע כאמור וכן שמסירת המידע תלויה ברצונו ובהסכמתו של המשתמש. עם זאת, מילוי חלק מהשדות בהליך הרישום לאתר מהווה תנאי הכרחי לשימוש לסיום הליך ההרשמה.<br /><br />
                4. המשתמש מצהיר ומתחייב בזאת, כי כל המידע שימסור ו/או יעדכן באתר, הינו נכון, אמין ומדויק וכן, כי ביצע את ההרשמה ו/או מסר את המידע בשמו ועבור עצמו בלבד ולא בשם ו/או עבור צדדים שלישיים, למעט במקרים בהם הורשה לעשות כן, במפורש.<br /><br />
                5. מבלי לגרוע מהאמור לעיל יובהר, כי במקרה שבו משתמש מבצע רכישה עבור צד שלישי כלשהו הוא מתחייב, כי כל הפרטים אותם ימסור באתר הנם נכונים, אמינים וכי הפרטים מצויים בידיו כדין וכי קיבל הרשאה מאותם צדדים שלישיים למסירת פרטים אלו, לרבות הסכמתם לקבלת דיוור מהנהלת האתר במייל ו/או בטלפון.<br /><br />
                6. המידע שמסר המשתמש, אף במצב אורח, וכן מידע נוסף אשר יצטבר אודותיו ופעולותיו באמצעות האתר ישמרו במאגר המידע של הנהלת האתר, המפורט לעיל, ויעשה בו שימוש בהתאם למטרות המאגר המפורטות לעיל ובהתאם למדיניות פרטיות זו ו/או בהתאם להוראות כל דין.<br /><br />
                7. הנהלת האתר שומרת לעצמה את הזכות לקבוע כי גישה לשירותים מסוימים ו/או לאזורים מסוימים באתר תתאפשר רק למשתמש אשר נרשם לאתר ומסר פרטים שידרשו ממנו. משתמש המעוניין להירשם כמשתמש רשום יהא חייב לפתוח חשבון משתמש ולמלא בו את הפרטים שידרשו לצורך האמור. המשתמש הרשום מתחייב למלא, כאמור בסעיף זה, אך ורק פרטים נכונים וכי יפתח חשבון משתמש אך עבור עצמו.<br /><br />

                ג. איסוף ושימוש במידע<br />
                1. הנהלת האתר רשאית לאסוף וכן לעשות שימוש במידע שנמסר על ידי המשתמש במועד ההרשמה ו/או במידע אשר הצטבר אודות המשתמש, לרבות במהלך השימוש באתר ובמהלך הצפייה והשימוש בתכנים ו/או הקורסים שהאתר מציע, על מנת לשפר, להעשיר או לשנות (לרבות שינוי של האתר המוצג למשתמש) את האתר, את השירותים והתכנים המוצעים באתר, וכן על מנת לעדכן את המשתמש ולערוך סקרי שביעות רצון באשר למבצעים ו/או שירותים המוצעים על ידי הנהלת האתר, מי מטעמה ו/או על ידי צדדים שלישיים עימם יש להנהלת האתר שיתוף פעולה עסקי. בנוסף, רשאית הנהלת האתר לעשות שימוש במידע למטרות שיווקיות וסטטיסטיות, ולמסור מידע סטטיסטי לצדדים שלישיים, לרבות מפרסמים וזאת בכפוף לתנאי מדיניות הפרטיות. מידע סטטיסטי זה לא יזהה את המשתמש אישית.<br /><br />
                2. כמו כן, רשאית הנהלת האתר לעשות שימוש במידע אשר נתקבל מאת המשתמש ו/או במידע שנאסף אודותיו, גם במקרים הבאים: לשם יצירת קשר עם המשתמש במקרה הצורך; לצורך תחזוקת האתר ו/או המערכת; לצורך שיפור המידע והתכנים שהנהלת האתר תציע במסגרת האתר למשתמשים באופן אישי ו/או לכלל ו/או לחלק מן המשתמשים באתר.<br /><br />
                3. מבלי לגרוע מהאמור לעיל, בכפוף למסירת המשתמש את פרטי ההתקשרות שלו להנהלת האתר והסכמתו לקבלת דיוור ישיר, תהיה הנהלת האתר רשאית לשלוח למשתמש, מעת לעת, בדואר אלקטרוני ו/או באמצעי התקשרות אחרים אשר מסר המשתמש, מידע בדבר שירותיה וכן מידע שיווקי ופרסומי של הנהלת האתר. יובהר, כי הדיוור מתבצע באמצעות מערכת, אשר מסופקת להנהלת האתר על ידי ספק חיצוני, אשר נותן שירותים להנהלת האתר. לפיכך ידוע למשתמש כי הנהלת האתר לא תישא באחריות במקרה של תביעה על פי חוק הספאם ו/או במקרה של תקלה שאינה בשליטת הנהלת האתר. המשתמש יהיה רשאי לבטל בכל עת את הסכמתו ולחדול מקבלת המידע באמצעות לחצן "הסרה" אשר יופיע בתחתית ההודעה.<br /><br />
                
                4. האתר משתמש בעוגיות (להלן "Cookies" ו/או "עוגיות") לצורך תפעולו השוטף והתקין, ובכלל זה כדי לאסוף נתונים סטטיסטיים אודות השימוש באתר, לאימות פרטים, כדי להתאים את האתר להעדפות האישיות של המשתמש, להקל על גלישתו של המשתמש באתר, לאפיין את המוצרים המתאימים לו, להתאים לגולש פרסומות מותאמות, אף בגלישתו באתרים אחרים, למטרות סטטיסטיות, מחקריות ומסחריות כאחד, וכמובן לצרכי אבטחת מידע.<br /><br />
                5. עוגיות הן קבצי טקסט, אשר הדפדפן של המשתמש יוצר לפי פקודה ממחשבי הנהלת האתר. חלק מהעוגיות יפקעו כאשר תסגור את הדפדפן ואחרות נשמרות על גבי הכונן הקשיח במחשב שלך. העוגיות מכילות מידע מגוון כדוגמת הדפים שבהם ביקרת, משך הזמן ששהית באתר, מהיכן הגעת אל האתר, מדורים ומידע שהמשתמש מבקש לראות בעת הכניסה הבאה שלו, נתוני התחברות ועוד.<br /><br />
                6. בנוסף, הנהלת האתר עושה שימוש בטכנולוגיות נוספות לצורך שיפור השירותים המוצעים על ידה.<br /><br />
            </p>
        </div>
    );
};

export default PrivacyPolicy;
