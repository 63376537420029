import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserManagement.css'; // Add your CSS styling
import Layout from './Layout';

const UserManagementPage = () => {
    const [users, setUsers] = useState([]); // Initialize as an empty array
    const [selectedUser, setSelectedUser] = useState(null);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        numberOfChildren: 0,
        role: 'user'
    });
    const [searchTerm, setSearchTerm] = useState(''); // State for search term

    const token = localStorage.getItem('token'); // Assuming token is stored in local storage
    const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const secretId = process.env.REACT_APP_SECRET_ID;

    // Fetch users from API
    useEffect(() => {
        const fetchUsers = async () => {
            const headers = {
                'client_id': clientId,
                'secret_id': secretId,
                'Authorization': `Bearer ${token}`
            };

            try {
                const response = await axios.get(`${apiUrl}/api/v1.0.0/users/list`, { headers });
                setUsers(response.data); // Assuming response.data is the array of user objects
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, [token]);

    // Handle user selection and populate form
    const handleUserClick = (user) => {
        setSelectedUser(user);
        setFormData({
            firstName: user.first_name,
            lastName: user.last_name,
            numberOfChildren: user.number_of_children,
            role: user.role
        });
    };

    // Handle form input changes
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    // Handle form submission for updating user details
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!selectedUser) return;

        const headers = {
            'client_id': clientId,
            'secret_id': secretId,
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const requestData = {
            user_id: selectedUser.user_id, 
            first_name: formData.firstName,  // Match the server field
            last_name: formData.lastName,    // Match the server field
            number_of_children: formData.numberOfChildren,
            role: formData.role
        };

        try {
            const response = await axios.put(`${apiUrl}/api/v1.0.0/users/profile`, requestData, { headers });
            console.log('User updated successfully:', response.data);
            // Optionally refresh user list or give feedback to the user
        } catch (error) {
            console.error('Error updating user:', error.response ? error.response.data : error.message);
        }
    };

    // Handle password reset
    const handleResetPassword = async () => {
        if (!selectedUser) return;

        const headers = {
            'client_id': clientId,
            'secret_id': secretId,
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        try {
            const response = await axios.put(`${apiUrl}/api/v1.0.0/users/password`, {
                user_id: selectedUser.user_id,
                new_password: 'new_password_here' // Replace with actual password logic
            }, { headers });
            console.log('Password reset successfully:', response.data);
            // Provide feedback to the user about the password reset
        } catch (error) {
            console.error('Error resetting password:', error.response ? error.response.data : error.message);
        }
    };

    // Filter users based on search term
    const filteredUsers = users.filter(user => 
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Layout>
        <div id="user-management-container">
            <div id="user-list">
                <h2>Users</h2>
                <input 
                    type="text" 
                    placeholder="Search by email..." 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)} 
                />
                <ul id="users">
                    {filteredUsers.map((user) => (
                        <li 
                            key={user.user_id} // Use user_id as the key
                            onClick={() => handleUserClick(user)} 
                            className={selectedUser && selectedUser.user_id === user.user_id ? 'selected' : ''}
                        >
                            {user.email} 
                        </li>
                    ))}
                </ul>
            </div>
            <div id="user-details">
                <h2>User Details</h2>
                {selectedUser ? (
                    <form id="user-form" onSubmit={handleFormSubmit}>
                        <input type="hidden" id="user-id" value={selectedUser.user_id} />
                        <label htmlFor="firstName">First Name:</label>
                        <input
                            type="text"
                            id="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            required
                        /><br />
                        <label htmlFor="lastName">Last Name:</label>
                        <input
                            type="text"
                            id="lastName"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            required
                        /><br />
                        <label htmlFor="numberOfChildren">Number of Children:</label>
                        <input
                            type="number"
                            id="numberOfChildren"
                            value={formData.numberOfChildren}
                            onChange={handleInputChange}
                            required
                        /><br />
                        <label htmlFor="role">Role:</label>
                        <select id="role" value={formData.role} onChange={handleInputChange} required>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                        </select><br />
                        <button type="submit">Update User</button>
                    </form>
                ) : (
                    <p>Please select a user to view and edit their details.</p>
                )}
                <button id="reset-password" onClick={handleResetPassword} disabled={!selectedUser}>
                    Reset Password
                </button>
            </div>
        </div>
        </Layout>
    );
};

export default UserManagementPage;
