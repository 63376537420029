// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* About.css */
.about-container {
    padding: 20px;
    line-height: 1.6;
    text-align: left;
    direction: ltr; /* Ensures left alignment for all content */
    max-width: 800px; /* Adjust this value to set the maximum width for text */
    margin: auto; /* Centers the container */
    position: relative; /* Needed for absolute positioning of the logo */
}



.about-container h1, 
.about-container h2 {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.about-container p {
    text-align: left;
    margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/About.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,cAAc,EAAE,2CAA2C;IAC3D,gBAAgB,EAAE,wDAAwD;IAC1E,YAAY,EAAE,0BAA0B;IACxC,kBAAkB,EAAE,gDAAgD;AACxE;;;;AAIA;;IAEI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":["/* About.css */\r\n.about-container {\r\n    padding: 20px;\r\n    line-height: 1.6;\r\n    text-align: left;\r\n    direction: ltr; /* Ensures left alignment for all content */\r\n    max-width: 800px; /* Adjust this value to set the maximum width for text */\r\n    margin: auto; /* Centers the container */\r\n    position: relative; /* Needed for absolute positioning of the logo */\r\n}\r\n\r\n\r\n\r\n.about-container h1, \r\n.about-container h2 {\r\n    text-align: left;\r\n    margin-top: 20px;\r\n    margin-bottom: 10px;\r\n    font-weight: bold;\r\n}\r\n\r\n.about-container p {\r\n    text-align: left;\r\n    margin-bottom: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
