// src/pages/ChatPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';
import Layout from '../components/Layout';
import './ChatPage.css';

const ChatPage = () => {
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const fetchSupervisedQuestions = async () => {
            const token = localStorage.getItem('token');
            const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
            const clientId = process.env.REACT_APP_CLIENT_ID;
            const secretId = process.env.REACT_APP_SECRET_ID;

// Log the values to the console

console.log('API URL:', apiUrl);
console.log('Client ID:', clientId);
console.log('Secret ID:', secretId);

            const headers = {
                'client_id': clientId,
                'secret_id': secretId,
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            try {
                const response = await axios.get(`${apiUrl}/api/v1.1.0/chat/questions/supervised-answers`, {
                    headers,
                });
                const answeredQuestions = response.data.answered_questions;

                if (answeredQuestions && answeredQuestions.length > 0) {
                    const formattedMessages = answeredQuestions.map((question) => ([ 
                        { user: 'User', text: question.user_message },
                        { user: 'BaLaolam', text: question.system_message }
                    ])).flat();

                    setMessages(formattedMessages);
                } else {
                    console.log('No supervised questions available.');
                }
            } catch (error) {
                console.error('Error fetching supervised questions:', error);
                setError('Failed to load supervised questions. Please try again later.');
            }
        };

        fetchSupervisedQuestions();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!userInput.trim()) return;

        setLoading(true);
        setError('');

        const token = localStorage.getItem('token');
        const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const secretId = process.env.REACT_APP_SECRET_ID;

        const headers = {
            'client_id': clientId,
            'secret_id': secretId,
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };

        const data = { user_message: userInput };

        try {
            const response = await axios.post(`${apiUrl}/api/v1.1.0/chat/questions/create`, data, { headers });

            if (response.data.system_reply) {
                const newMessage = response.data;
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { user: 'You', text: userInput },
                    { user: 'BaLaolam', text: newMessage.system_reply },
                ]);
            } else {
                setError('No response from the bot.');
            }

            setUserInput('');
        } catch (error) {
            console.error('Error sending message:', error);
            setError('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token'); // Clear the token
        navigate('/'); // Redirect to login page
    };

    return (
        <Layout>
            <button onClick={handleLogout} className="logout-btn">LOGOUT</button> {/* Logout Button */}
            <div id="chat-window">
                {messages.map((msg, index) => (
                    <div key={index} className={msg.user === 'BaLaolam' ? 'bot-message' : 'user-message'}>
                        <strong>{msg.user}: </strong>{msg.text}
                    </div>
                ))}
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>

            <form id="chat-form" onSubmit={handleSubmit} className="chat-form">
                <div className="input-container">
                    <textarea
                        className="textarea"
                        id="user-input"
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                        placeholder="Type your message..."
                        disabled={loading}
                    />
                    <button id="send-btn" type="submit" disabled={loading}>
                        {loading ? 'Sending...' : 'Send'}
                    </button>
                </div>
            </form>

        </Layout>
    );
};

export default ChatPage;
