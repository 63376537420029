import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './SuperviseQuestions.css';
import Layout from './Layout'; // Assuming your Layout component is in the same folder

const QuestionsPage = () => {
    // API configurations
    const apiUrl = 'https://api-test.balaolam.com';
    const clientId = 'balaolam-daniel';
    const secretId = 'd629d01d-2b16-436e-9b19-de0de405d2be';

    const token = localStorage.getItem('token'); // Assuming you store the token in local storage

    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [answer, setAnswer] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch questions from the API
    const getQuestions = async () => {
        const headers = {
            'client_id': clientId,
            'secret_id': secretId,
            'Authorization': `Bearer ${token}`,
        };

        try {
            const response = await axios.get(`${apiUrl}/api/v1.1.0/supervise/questions`, { headers });
            console.log('Fetched Questions:', response.data); // Log the response data to inspect its structure
            setQuestions(response.data);
            if (response.data.length > 0) {
                setCurrentQuestion(response.data[0]); // Set the first question as the current one
                setAnswer(response.data[0].system_answer_translated || '');
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
            setError('Failed to load questions. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    // Submit an answer to the selected question
    const answerQuestion = async (qna_round_id, user_id) => {
        const headers = {
            'client_id': clientId,
            'secret_id': secretId,
            'Authorization': `Bearer ${token}`,
        };

        const data = {
            answer: answer,
            user_id: user_id,
        };

        try {
            const response = await axios.post(`${apiUrl}/api/v1.1.0/supervise/questions/${qna_round_id}/answer`, data, { headers });
            console.log('Answer submitted:', response.data);
            // Optionally, you can refetch questions or update the state accordingly
        } catch (error) {
            console.error('Error submitting answer:', error);
        }
    };

    // Handle question selection
    const handleQuestionSelect = (question) => {
        setCurrentQuestion(question);
        setAnswer(question.system_answer_translated || '');
    };

    // Handle answer submission
    const handleSubmitAnswer = () => {
        if (currentQuestion) {
            answerQuestion(currentQuestion.qna_round_id, currentQuestion.user_id);
        }
    };

    // Show chat history
    const handleChatHistory = () => {
        if (currentQuestion && currentQuestion.chat_history) {
            const chatHistory = currentQuestion.chat_history.join('\n'); // Join the array with new lines
            alert(chatHistory); // Show the chat history in an alert
        }
    };

    // Show metadata
    const handleMetadata = () => {
        if (currentQuestion) {
            const metadata = `Page: ${currentQuestion.documents[0].page_content}\nSource: ${currentQuestion.source}`; // Adjust according to your metadata structure
            alert(metadata); // Show metadata in an alert
        }
    };

    useEffect(() => {
        getQuestions();
    }, []);

    if (loading) return <p>Loading questions...</p>;
    if (error) return <p>{error}</p>;

    return (
        <Layout>
            <h1>Unanswered Questions ({questions.length} Total)</h1>
            <div className="container" style={{ display: 'flex' }}>
                <div className="left" style={{ flex: 1, padding: '10px' }}>
                    {currentQuestion ? (
                        <div>
                            <h2>User Question:</h2>
                            <p>{currentQuestion.user_question_en}</p>
                            <h2>Bot's Response:</h2>
                            <p>{currentQuestion.system_answer_en || 'No response available'}</p>
                            <div>
                                <textarea
                                    id="answer"
                                    name="answer"
                                    rows="4"
                                    cols="70"
                                    value={answer}
                                    onChange={(e) => setAnswer(e.target.value)} // Update the answer state
                                />
                            </div>
                            <button onClick={handleSubmitAnswer}>Save Answer</button>
                            <button onClick={handleChatHistory}>Show Chat History</button>
                            <button onClick={handleMetadata}>Show Metadata</button>
                        </div>
                    ) : (
                        <p>Select a question to view details.</p>
                    )}
                </div>
                <div className="right" style={{ flex: 1, padding: '10px', borderLeft: '1px solid #ccc', maxHeight: '80vh', overflowY: 'auto' }}>
                    <h2>All Questions</h2>
                    <ul>
                        {questions.length > 0 ? (
                            questions.map((question) => (
                                <li
                                    key={question[10]} // Adjust this key as per your data structure
                                    onClick={() => handleQuestionSelect(question)} // Click to select a question
                                    style={{ cursor: 'pointer', marginBottom: '10px' }}
                                >
                                    {question.user_question}
                                </li>
                            ))
                        ) : (
                            <p>No questions available.</p>
                        )}
                    </ul>
                </div>
            </div>
        </Layout>
    );
};

export default QuestionsPage;
