import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Layout.css';

const Layout = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [role, setRole] = useState(null);

    useEffect(() => {
        // אחזור ה-role מה-localStorage בעת טעינת הקומפוננטה
        const userRole = localStorage.getItem('role');
        if (userRole) {
            setRole(userRole); // הגדרת ה-state של ה-role אם נמצא
        }
    }, []);

    const toggleMenu = () => {
        setMenuOpen((prevState) => !prevState);
    };

    return (
        <div className="layout-container">
            <div id="menu-wrapper">
                {!menuOpen && (
                    <button className="menu-btn" onClick={toggleMenu} aria-label="Open sidebar">
                        &#9776;
                    </button>
                )}
            </div>

            <div className={`sidebar ${menuOpen ? 'open' : ''}`}>
                {menuOpen && (
                    <button className="close-btn" onClick={toggleMenu} aria-label="Close sidebar">×</button>
                )}
                <nav>
                    {/* קישורים שכל המשתמשים רואים */}
                    <Link to="/chat">Home</Link>
                    <Link to="/about">About</Link>
                    <Link to="/baby-details">Baby Details</Link>

                    {/* קישורים שנראים רק לאדמין */}
                    {role === 'admin' && (
                        <>
                            <Link to="/user-management">User Management</Link>
                            <Link to="/supervise-questions">Supervise Questions</Link>
                        </>
                    )}
                </nav>
            </div>

            <div className="content">
                {children}
            </div>

            {/* אזור ה-footer */}
            <footer className="chat-footer">
                <span>For contact: Adi@balaolam.co.il, 054-7247414</span>
                <a href="/accessibility-information" target="_blank" rel="noopener noreferrer" className="footer-link">
                    <strong> Accessibility </strong>
                </a>
                {' | '}
                <a href="/website-regulations" target="_blank" rel="noopener noreferrer" className="footer-link">
                    <strong> Website Regulations </strong>
                </a>
            </footer>

            {/* התמונה בפינה הימנית-תחתונה */}
            <img src="/images/didi.png" alt="Logo" className="logo-background" />
        </div>
    );
};

export default Layout;
