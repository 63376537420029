import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './SignUp.css';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [photo, setPhoto] = useState(null);
    const [termsChecked, setTermsChecked] = useState(false);
    const [error, setError] = useState('');
    const [gender, setGender] = useState(''); // New state for gender
    const [dateOfBirth, setDateOfBirth] = useState(''); // New state for date of birth
    const navigate = useNavigate();

    const handleSignUp = async (e) => {
        e.preventDefault();

        if (!termsChecked) {
            setError('You must agree to the terms and privacy policy.');
            return;
        }

        const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const secretId = process.env.REACT_APP_SECRET_ID;

        const headers = {
            'client_id': clientId,
            'secret_id': secretId,
            'Content-Type': 'application/json',
        };

        const data = {
            email,
            password,
            first_name: firstName,
            last_name: lastName,
            number_of_children: 1,
            gender, // New gender field
            date_of_birth: dateOfBirth, // New date of birth field
        };

        try {
            const response = await axios.post(`${apiUrl}/api/v1.0.0/auth/register`, data, { headers });

            if (response.data) {
                navigate('/');
            } else {
                setError('Sign-up failed. Please try again.');
            }
        } catch (error) {
            console.error('Sign-up error:', error);
            setError('Sign-up failed. Please try again.');
        }
    };

    const handleTermsClick = () => {
        window.open('/privacy-policy', '_blank');
    };

    return (
        <div className="signup-container">
            <h2>Sign Up</h2>
            <form onSubmit={handleSignUp}>
                <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                        type="text"
                        id="firstName"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                        type="text"
                        id="lastName"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="gender">Gender</label>
                    <select
                        id="gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        required
                    >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="dateOfBirth">Date of Birth</label>
                    <input
                        type="date"
                        id="dateOfBirth"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Add Child Picture</label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setPhoto(e.target.files[0])}
                    />
                </div>

                <div className="form-group terms">
                    <input
                        type="checkbox"
                        id="terms"
                        checked={termsChecked}
                        onChange={(e) => setTermsChecked(e.target.checked)}
                        required
                    />
                    <label htmlFor="terms">
                        I agree to the
                        <span onClick={handleTermsClick} className="terms-link">
                            {' '}מדיניות פרטיות
                        </span>
                    </label>
                </div>

                <button type="submit" className="submit-btn">Sign Up</button>
                {error && <p className="error">{error}</p>}
            </form>
        </div>
    );
};

export default SignUp;
