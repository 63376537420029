import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Login from './components/Login';
import SignUp from './components/SignUp';
import ChatPage from './components/ChatPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import WebsiteRegulations from './components/Regulations';
import AccessibilityInformation from './components/Accessibility';
import About from './components/About';
import SuperviseQuestions from './components/SuperviseQuestions';
import UserManagement from './components/UserManagement';
import BabyDetails from './components/BabyDetails'; // Import BabyDetails component

function App() {
    return (
        <Router>
           
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/chat" element={<ChatPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/website-regulations" element={<WebsiteRegulations />} />
                    <Route path="/accessibility-information" element={<AccessibilityInformation />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/supervise-questions" element={<SuperviseQuestions />} />
                    <Route path="/user-management" element={<UserManagement />} />
                    <Route path="/baby-details" element={<BabyDetails />} /> {/* Add Baby Details Route */}
                
                </Routes>
            
        </Router>
    );
}

export default App;
