import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './BabyDetails.css';
import Layout from '../components/Layout';

const BabyDetails = () => {
    const [date, setDate] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [headCircumference, setHeadCircumference] = useState('');
    const [latestData, setLatestData] = useState({});
    const [growthAverages, setGrowthAverages] = useState({});
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');
    const clientId = process.env.REACT_APP_CLIENT_ID;;
    const secretId = process.env.REACT_APP_SECRET_ID;
    const apiUrl  = process.env.REACT_APP_BACKEND_API_URL;
    console.log('API URL:', apiUrl);
console.log('Client ID:', clientId);
console.log('Secret ID:', secretId);

    const headers = {
        'client_id': clientId,
        'secret_id': secretId,
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    const getMinDate = () => {
        const storedDate = localStorage.getItem('date');
        if (!storedDate) return '';
        const birthDate = new Date(storedDate);
        return birthDate.toISOString().split('T')[0];
    };

    const getMaxDate = () => {
        const storedDate = localStorage.getItem('date');
        if (!storedDate) return '';
        const birthDate = new Date(storedDate);
        birthDate.setFullYear(birthDate.getFullYear() + 2);
        const today = new Date();
        return birthDate > today ? today.toISOString().split('T')[0] : birthDate.toISOString().split('T')[0];
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const [latestRes, averagesRes] = await Promise.all([
                    axios.get(`${apiUrl}?types=weight,height,headCircumference`, { headers }),
                    axios.get(`${apiUrl}/growth-averages?types=weight,height,headCircumference`, { headers }),
                ]);
                setLatestData(latestRes.data || {});
                setGrowthAverages(averagesRes.data || {});
                setError('');
            } catch (err) {
                setError('Failed to load data. Please try again.');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (!date || (!weight && !height && !headCircumference)) {
            setError('Date is required along with at least one field: weight, height, or head circumference.');
            return;
        }

        const dataEntries = [
            weight && { data_type: 'weight', data_value: Number(weight), data_date: date },
            height && { data_type: 'height', data_value: Number(height), data_date: date },
            headCircumference && { data_type: 'headCircumference', data_value: Number(headCircumference), data_date: date },
        ].filter(Boolean);

        try {
            await axios.post(apiUrl, dataEntries, { headers });
            setError('');
            alert('Details updated successfully!');
        } catch (err) {
            setError('Failed to update details. Please try again.');
        }
    };

    const generateChartData = (userData = [], label) => {
        // Filter user data to include only points with x between 0 and 24
        const filteredUserDataset = userData
        .filter(point => point.x >= 0 && point.x <= 24) // שמירה על ערכים בטווח
        .map(point => ({
            x: Math.round(point.x), // עיגול לערכים קרובים ביותר
            y: point.y
        }));
    

        // Generate 24 coordinates for the X-axis (0 to 24)
        const xAxisLabels = Array.from({ length: 25 }, (_, i) => i); // 0 to 24

        return {
            labels: xAxisLabels, // Fixed X-axis labels
            datasets: [
                {
                    label: 'Your Data',
                    data: xAxisLabels.map(x =>
                        filteredUserDataset.find(point => point.x === x)?.y || null // Match user data or fill gaps with null
                    ),
                    borderColor: '#079DB2',
                    backgroundColor: 'transparent',
                    borderWidth: 2,
                    spanGaps: true, // Keep gaps where there is no data
                },
                ...(growthAverages[label]
                    ? growthAverages[label].map((lineData, index) => ({
                          label: lineData.chart_name.replace(label, '').trim(),
                          data: lineData.points.map(point => point.y), // Percentile points
                          borderColor: `hsl(${(index * 60) % 360}, 100%, 50%)`,
                          borderWidth: 1,
                      }))
                    : []),
            ],
        };
    };

    const chartOptions = (chartTitle) => ({
        plugins: {
            title: {
                display: true,
                text: chartTitle,
            },
        },
        scales: {
            x: { title: { text: 'MONTHS', display: true } },
            y: { title: { text: chartTitle, display: true } },
        },
    });

    return (
        <Layout>
            <div className="baby-details-container">
                <h2>Update Baby Details</h2>
                <form onSubmit={handleUpdate}>
                    <div className="form-group">
                        <label>Date</label>
                        <input
                            type="date"
                            value={date}
                            min={getMinDate()}
                            max={getMaxDate()}
                            onChange={(e) => setDate(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Weight (kg)</label>
                        <input type="number" value={weight} onChange={(e) => setWeight(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Height (cm)</label>
                        <input type="number" value={height} onChange={(e) => setHeight(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Head Circumference (cm)</label>
                        <input type="number" value={headCircumference} onChange={(e) => setHeadCircumference(e.target.value)} />
                    </div>
                    <button type="submit">Update Details</button>
                    {error && <p className="error">{error}</p>}
                </form>

                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        <h3>Baby Growth Charts</h3>
                        <div className="chart-container">
                            {latestData.weight && (
                                <Line data={generateChartData(latestData.weight, 'weight')} options={chartOptions('Weight')} />
                            )}
                            {latestData.height && (
                                <Line data={generateChartData(latestData.height, 'height')} options={chartOptions('Height')} />
                            )}
                            {latestData.headCircumference && (
                                <Line data={generateChartData(latestData.headCircumference, 'headCircumference')} options={chartOptions('Head Circumference')} />
                            )}
                        </div>
                    </>
                )}
            </div>
        </Layout>
    );
};

export default BabyDetails;
