// About.js
import React from 'react';
import Layout from './Layout';
import './About.css'; // Import the CSS file for styling

const About = () => {
    return (
        <Layout>
            <div className="about-container">
                <h1>About</h1>
                <p><strong>Welcome to  DiDI from BaLaOlam</strong></p>
                <p>
                    At DiDI from BaLaOlam, our mission is to empower every parent with confidence and knowledge 
                    throughout their parenting journey. Our platform offers real-time professional guidance on all 
                    aspects of infant and child care, including breastfeeding, sleep, nutrition, and overall 
                    development.
                </p>
                <p>
                    Utilizing the world’s first conversational AI system dedicated to modern parenting, we provide 
                    parents with personalized, reliable, and precise solutions that significantly enhance the 
                    parenting experience. Our system is designed to assist with a wide range of parenting questions, 
                    using advanced algorithms that also track your child’s growth.
                </p>
                <p>
                    DiDI analyzes key data, such as weight, height, and head circumference, comparing it to global 
                    medical standards to give you peace of mind regarding your child’s healthy development. All our 
                    advice is rigorously validated by leading medical experts, ensuring you receive the most accurate 
                    and comprehensive assistance available—anytime, anywhere. We are here to support you at every stage.
                </p>

                <h2>The Vision Behind DiDI</h2>
                <p>
                    DiDI from BaLaOlam was founded by <strong>Adi Korngold Hayon</strong> as part of her vision to 
                    revolutionize modern parenting. After years of working closely with parents and newborns, Adi 
                    realized the growing need for a platform that could provide accessible, professional, and reliable 
                    support for the new generation of parents.
                </p>
                <p>
                    With over 13 years of experience as a nurse, lactation consultant, and sleep consultant, alongside 
                    her extensive work in leading medical centers around the world, Adi launched DiDI to bridge the gap 
                    between expert medical advice and the everyday needs of parents.
                </p>

                <h2>Why Choose Us?</h2>
                <p>
                    By joining DiDI from BaLaOlam, you become part of a community committed to reshaping the future of 
                    parenting. The combination of professionally validated advice, personalized solutions, and 
                    accessible, advanced technology makes our platform a pioneer in this field.
                </p>
                <p>
                    Whether you are at home or on the go, DiDI ensures that high-quality, accurate guidance is always 
                    just a click away. Moreover, with DiDI’s ability to track your child’s growth and alert you to any 
                    irregularities or potential developmental issues, you can be confident that your child’s growth is 
                    being closely monitored against the highest medical standards.
                </p>
                
            </div>
        </Layout>
    );
};

export default About;
