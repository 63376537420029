import React from 'react';

const Accessibility = () => {
    return (
        <div className="accessibility-container">
            <h1>הצהרת נגישות</h1>
            
            <section className="intro">
                <p>אנו רואים חשיבות רבה במתן שירות שוויוני לכלל הלקוחות והגולשים ובשיפור השירות הניתן ללקוחות ולגולשים עם מוגבלות. לפיכך אנו משקיעים משאבים רבים בהנגשת האתר שלנו על מנת להפוך אותו לזמין יותר עבור אנשים עם מוגבלות.</p>
            </section>

            <section className="accessibility-info">
                <h2>נגישות אתר האינטרנט</h2>
                <p>הנגשת האתר נועדה להפוך אותו לזמין, ידידותי ונוח יותר לשימוש עבור אנשים עם צרכים מיוחדים, הנובעים בין היתר ממוגבלויות מוטוריות שונות, לקויות קוגניטיביות, קוצר רואי, עיוורון או עיוורון צבעים, לקויות שמיעה וכן אנשים בני הגיל השלישי.</p>
                <p>אנו עושים מאמצים שהאתר שלנו יעמוד בדרישות תקנות שיוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות) התשע”ג 2013, ברמת התקן הנדרש. כמו כן, אנו מיישמים את המלצות מסמך WCAG2.0 מאת ארגון W3C.</p>
            </section>

            <section className="improvements">
                <h2>תיקונים והתאמות שבוצעו</h2>
                <ul>
                    <li>רוב האתר מותאם לדפדפנים הנפוצים.</li>
                    <li>רוב האתר מותאם לשימוש בטלפון הסלולרי.</li>
                    <li>רוב האתר מותאם לתצוגה תואמת מגוון מסכים ורזולוציות.</li>
                    <li>רוב אמצעי הניווט באתר פשוטים וברורים.</li>
                    <li>רוב תכני האתר כתובים באופן ברור, מסודר והיררכי.</li>
                    <li>רוב תוכן האתר כתוב בשפה פשוטה וברורה.</li>
                    <li>רוב הדפים באתר בעלי מבנה קבוע.</li>
                    <li>למרבית התמונות באתר יש הסבר טקסטואלי חלופי (alt).</li>
                    <li>רוב האתר מאפשר שינוי גודל הגופן על ידי שימוש במקש Ctrl ומקש + במקלדת.</li>
                    <li>ברוב האתר אין שימוש בתצוגת טקסט נע או מהבהב.</li>
                </ul>
            </section>

            <section className="issues">
                <h2>חשוב לציין</h2>
                <p>למרות מאמצינו להנגיש את כלל הדפים באתר, ולמרות פעילותינו לשפר את נגישות האתר – ייתכן שיתגלו חלקים או יכולות שלא הונגשו כראוי או שטרם הונגשו.</p>
            </section>

            <section className="contact-us">
                <h2>אם נתקלתם בבעיה בנושא נגישות באתר</h2>
                <p>נשמח אם תכתבו לנו, באמצעות פנייה לרכז הנגישות שלנו במייל. כדי שנוכל לטפל בבעיה בדרך הטובה ביותר, אנו ממליצים מאוד לצרף פרטים מלאים ככל שניתן:</p>
                <ul>
                    <li>תיאור הבעיה</li>
                    <li>מהי הפעולה שניסיתם לבצע</li>
                    <li>קישור לדף בו גלשתם</li>
                    <li>סוג הדפדפן וגרסתו</li>
                    <li>מערכת הפעלה</li>
                </ul>
            </section>

            <section className="contact-info">
                <h2>פרטי רכז הנגישות במשרדנו:</h2>
                <p>שם: קרלוס חיון</p>
                <p>טלפון: 050-5066929</p>
                <p>דוא”ל: <a href="mailto:carloshayon1978@gmail.com">carloshayon1978@gmail.com</a></p>
                <p>הצהרת הנגישות עודכנה ביום: 23/01/2024</p>
            </section>

            <section className="english-version">
                <h2>Accessibility Statement</h2>
                <p>Updated: 23/01/2024.</p>

                <h3>General</h3>
                <p>BaLaOlam strives to ensure that its services are accessible to people with disabilities. www.babybait.co.il has invested a significant amount of resources to help ensure that its website is made easier to use and more accessible for people with disabilities, with the strong belief that every person has the right to live with dignity, equality, comfort and independence.</p>

                <h3>Accessibility on BaLaOlam</h3>
                <p>BaLaOlam makes available the UserWay Website Accessibility Widget that is powered by a dedicated accessibility server. The software allows BaLaOlam to improve its compliance with the Web Content Accessibility Guidelines (WCAG 2.1).</p>

                <h3>Enabling the Accessibility Menu</h3>
                <p>The BaLaOlam accessibility menu can be enabled by clicking the accessibility menu icon that appears on the corner of the page. After triggering the accessibility menu, please wait a moment for the accessibility menu to load in its entirety.</p>

                <h3>Disclaimer</h3>
                <p>BaLaOlam continues its efforts to constantly improve the accessibility of its site and services in the belief that it is our collective moral obligation to allow seamless, accessible and unhindered use also for those of us with disabilities.</p>
                <p>In an ongoing effort to continually improve and remediate accessibility issues, we also regularly scan BaLaOlam with User Way’s Accessibility Scanner to identify and fix every possible accessibility barrier on our site. Despite our efforts to make all pages and content on BaLaOlam fully accessible, some content may not have yet been fully adapted to the strictest accessibility standards. This may be a result of not having found or identified the most appropriate technological solution.</p>

                <h3>Here For You</h3>
                <p>If you are experiencing difficulty with any content on BaLaOlam or require assistance with any part of our site, please contact us during normal business hours as detailed below and we will be happy to assist.</p>

                <h3>Contact Us</h3>
                <p>If you wish to report an accessibility issue, have any questions or need assistance, please contact BaLaOlam Customer Support as follows:</p>
                <p>Email: <a href="mailto:carloshayon1978@gmail.com">carloshayon1978@gmail.com</a></p>
            </section>
        </div>
    );
};

export default Accessibility;
