// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const secretId = process.env.REACT_APP_SECRET_ID;

        const headers = {
            'client_id': clientId,
            'secret_id': secretId,
            'Content-Type': 'application/json',
        };

        const data = {
            email: email,
            password: password,
        };

        try {
            const response = await axios.post(`${apiUrl}/api/v1.0.0/auth/login`, data, { headers });

            if (response.data && response.data.access_token) {
                // שמירת ה-token ו-role ב-localStorage
                localStorage.setItem('token', response.data.access_token);
                localStorage.setItem('role', response.data.user.role); // שמירת role
                localStorage.setItem('date', response.data.user.date_of_birth);
                console.log(localStorage.getItem('date'));
                navigate('/chat');
            } else {
                setError('Invalid credentials');
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setError('Login failed. The server cannot be found (404).');
            } else {
                setError('Login failed. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-container">
            <h2>Login</h2>
            <form onSubmit={handleLogin}>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit" disabled={loading} className="login-btn">
                    {loading ? 'Logging in...' : 'Login'}
                </button>
                {error && <p className="error-message">{error}</p>}
            </form>

            <p className="signup-text">
                Don't have an account? 
                <button className="signup-btn" onClick={() => navigate('/signup')}>
                    Sign Up
                </button>
            </p>

            {/* Logo image */}
            <img src="/images/didi.png" alt="Logo" className="logo" />
        </div>
    );
};

export default Login;
